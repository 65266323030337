import React from "react";

import { Box } from "@mui/material";
import PropTypes from "prop-types";

import stylizeComponent from "styles/stylizeComponent";
import { getValueInNestedObject } from "utils/objectUtils";

const StyledBox = stylizeComponent(
  Box,
  ["isSelected", "displayBorder"],
  ({ theme, ...props }) => {
    let backGroundColor;
    let borderColor;

    switch (props.color) {
      case "primary":
        backGroundColor = theme.palette.primary.background;
        borderColor = props.isSelected
          ? theme.palette.primary.main
          : (theme.palette.customized.cardBorder || theme.palette.grey.grey3);
        break;
      case "default":
      default:
        backGroundColor = theme.palette.customized.cardBackground || theme.palette.background.default;
        borderColor = props.isSelected
          ? theme.palette.primary.main
          : (theme.palette.customized.cardBorder || theme.palette.grey.grey3);
        break;
    }

    backGroundColor = getValueInNestedObject(theme.palette, props.backgroundColor) || backGroundColor;
    borderColor = getValueInNestedObject(theme.palette, props.borderColor) || borderColor;

    return {
      "&.MuiBox-root": {
        backgroundColor: backGroundColor,
        padding: props.padding || "1.25rem",
        margin: props.margin || "0rem",
        borderRadius: "0.3125rem",
        border: props.displayBorder ? `solid 0.0625rem ${borderColor}` : null,
        ...(props.sx || {}),
      },
    };
  },
);

/**
 * A layout wrapper to embed components.
 */
const Container = ({
  children,
  padding,
  margin,
  color,
  backgroundColor,
  borderColor,
  displayBorder,
  isSelected,
  sx,
}) => (
  <StyledBox
    padding={padding}
    margin={margin}
    color={color}
    isSelected={isSelected}
    displayBorder={displayBorder}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    sx={sx}
  >
    {children}
  </StyledBox>
);

Container.propTypes = {
  /**
   * The component/content to wrap.
   */
  children: PropTypes.any.isRequired,
  /**
   * If we want to override the default paddings of the container.
   */
  padding: PropTypes.string,
  /**
   * If we want to override the default margins of the container.
   */
  margin: PropTypes.string,
  /**
   * The color theme of the container.
   */
  color: PropTypes.oneOf(["default", "primary", ""]),
  /**
   * The background color to override (instead of the default one computed by 'color').
   */
  backgroundColor: PropTypes.string,
  /**
   * The border color to override (instead of the default one computed by 'color'.
   */
  borderColor: PropTypes.string,
  /**
   * Whether we don't want to remove the borders of the card.
   */
  displayBorder: PropTypes.bool,
  /**
   * Whether the component is selected in order to display the UI / color of a selected block.
   */
  isSelected: PropTypes.bool,
  /**
   * The free style props (from 'mui').
   */
  sx: PropTypes.object,
};

Container.defaultProps = {
  padding: null,
  margin: null,
  color: "default",
  backgroundColor: null,
  borderColor: null,
  displayBorder: true,
  isSelected: false,
  sx: {},
};

export default Container;
