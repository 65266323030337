// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

export default function widgets(client) {
  const path = "/widgets";
  return {
    get: (id) => client.request({
      endpoint: `${path}/${id}`,
      method: "GET",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
  };
}
