import { queryParams } from "libs/api/interceptors";

export default function carts(client, config) {
  const path = `/widgets/${config.widgetUUID}/carts`;
  return {
    create: (data) => client.request({
      version: "v2",
      endpoint: `${path}`,
      method: "POST",
      body: data,
    }),
    delete: (id) => client.request({
      version: "v2",
      endpoint: `${path}/${id}`,
      method: "DELETE",
    }),
    get: (id) => client.request({
      version: "v2",
      endpoint: `${path}/${id}`,
      method: "GET",
    }),
    getWithPaymentReference: (id, paymentReference) => client.request({
      version: "v2",
      endpoint: `${path}/${id}?payment_reference=${paymentReference}`,
      method: "GET",
    }),
    list: (queryParamsInput) => client.request({
      version: "v2",
      endpoint: `${path}?ordering=-id`,
      method: "GET",
      interceptors: Object.values(queryParamsInput)?.length ? [queryParams(queryParamsInput)] : [],
    }),
    partialUpdate: (uuid, data) => client.request({
      version: "v2",
      endpoint: `${path}/${uuid}`,
      method: "PATCH",
      body: data,
    }),
    pay: (uuid, data) => client.request({
      version: "v2",
      endpoint: `${path}/${uuid}/pay`,
      method: "POST",
      body: data,
    }),
  };
}
