import { headers, saveAsFile } from "libs/api/interceptors";

export default function receipts(client, config) {
  const path = `/widgets/${config.widgetUUID}/receipts`;
  return {
    create: (data) => client.request({
      endpoint: path,
      method: "POST",
      body: data,
    }),
    list: () => client.request({
      endpoint: path,
      method: "GET",
    }),
    getPDF: (id, filename) => client.request({
      endpoint: `${path}/${id}`,
      method: "GET",
      responseInterceptors: [saveAsFile(filename)],
      interceptors: [headers({ accept: "application/pdf" })],
    }),
  };
}
