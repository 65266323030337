import { headers, saveAsFile } from "libs/api/interceptors";

/**
 * Api used to download the tax receipt corresponding to a transaction row payment.
 */
export default function taxreceipts(client, config) {
  const path = `/widgets/${config.widgetUUID}/tax-receipts`;
  return {
    getPDF: (id, filename) => client.request({
      endpoint: `${path}/${id}`,
      method: "GET",
      responseInterceptors: [saveAsFile(filename)],
      interceptors: [headers({ accept: "application/pdf" })],
    }),
  };
}
