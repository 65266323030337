import React from "react";

import { Localized } from "@fluent/react";
import PropTypes from "prop-types";

const FluentTranslate = ({ ...props }) => React.createElement(Localized, props);

FluentTranslate.propTypes = {
  /**
   * All the props to provide to the component.
   */
  props: PropTypes.any,
};

FluentTranslate.defaultProps = {
  props: null,
};

export default FluentTranslate;
