import React from "react";

/**
 * This context has to store the ID of the organizer.
 */
const WidgetUUIDContext = React.createContext(null);

if (process.env.NODE_ENV !== "production") {
  WidgetUUIDContext.displayName = "WidgetUUIDContext";
}

export default WidgetUUIDContext;
