import React from "react";

import I18nContext from "../contexts/I18nContext";

const useI18n = () => {
  const context = React.useContext(I18nContext);
  return context
    ? {
      locale: context.locale,
      allowedLocales: context.allowedLocales,
      isLocaleLocked: context.isLocaleLocked,
      setLocale: context.setLocale,
      setAllowedLocales: context.setAllowedLocales,
      setIsLocaleLocked: context.setIsLocaleLocked,
      getString: context.getString,
    }
    : {};
};

export default useI18n;
