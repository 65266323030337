/* eslint-disable */
import React from "react";

import ErrorPage from "pages/ErrorPage";

/**
 * Cf: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * An Error boundary is a component that catch any critical error that break the render
 * in order to be able to fall back on another default rerender and trace the error anyway.
 * Exceptionally, this component is not a functional component but a class,
 * only because there is no way to do it differently.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Change the state to change the render and display a pretty error message.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Display a pretty error message
      // TODO: Improve the UI of the error
      return (
        <div style={{ textAlign: "center" }}>
          <h2>We are sorry :(</h2>
          <h2>An error occured...</h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
