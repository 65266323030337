import React from "react";

import PropTypes from "prop-types";

import { DummyTranslate } from "../../backends/dummy";
import I18nContext from "../../contexts/I18nContext";

/**
 * The main component use to translate labels and texts from the key use in the translation file.
 */
const I18n = ({ ...props }) => {
  const i18n = React.useContext(I18nContext);
  if (!i18n) { return React.createElement(DummyTranslate, props); }
  return React.createElement(i18n.Translate, props);
};

I18n.propTypes = {
  /**
   * All the props to provide to the component.
   */
  props: PropTypes.any,
};

I18n.defaultProps = {
  props: null,
};

export default I18n;
