export default function AutoTopUps(client, config) {
  const path = `/widgets/${config.widgetUUID}/auto-top-ups`;
  return {
    create: (data) => client.request({
      endpoint: path,
      method: "POST",
      body: data,
    }),
    get: (id) => client.request({
      endpoint: `${path}/${id}`,
      method: "GET",
    }),
    list: () => client.request({
      endpoint: `${path}`,
      method: "GET",
    }),
    destroy: (id) => client.request({
      endpoint: `${path}/${id}`,
      method: "DELETE",
    }),
  };
}
