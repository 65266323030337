import { host, bodyRender, jsonRender, disconnect } from "./interceptors";

const composeInterceptors = async (action, interceptors) => {
  const [interceptor, ...next] = interceptors;
  return interceptor ? composeInterceptors(await interceptor(action), next) : action;
};

export default class Client {
  static DEFAULT_HOST = "https://api.weezevent.com/";

  static DEFAULT_VERSION = "v1";

  constructor(config) {
    this.organizationId = config.organizationId;
    this.host = config.host || Client.DEFAULT_HOST;
    this.version = config.version || Client.DEFAULT_VERSION;
    this.config = config;
  }

  async request(query) {
    this.basePath = `pay/${query.version || this.version}/public/organizations/${this.organizationId}`;

    const interceptors = [
      host(this.host, this.basePath),
      bodyRender(),
    ];

    const clientConfig = this.config || {};
    const clientInterceptors = [
      ...interceptors,
      ...(clientConfig.requestInterceptors || []),
      ...(query.interceptors || []),
    ];

    const requestQuery = await composeInterceptors(query, clientInterceptors);
    const { endpoint, ...init } = requestQuery;

    const fetchFunction = clientConfig.fetchFunction || fetch;
    const response = await fetchFunction(endpoint, init);
    if (
      !response.ok
      && response.status !== 401
      && response.status !== 403
      && (clientConfig?.throwErrorForStatuses?.includes(response.status) || !clientConfig?.throwErrorForStatuses)
    ) {
      // TODO: pass response through the interceptors
      throw response;
    }
    const handledResponse = await composeInterceptors(
      response,
      [
        disconnect(this.config.authentication),
        ...(clientConfig.responseInterceptors || []),
        ...(query.responseInterceptors || [jsonRender()]),
      ],
    );
    return handledResponse;
  }
}
