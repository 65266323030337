import { styled } from "@mui/material";

/**
 * This function is a function to simplify the stylization and the customization of the components
 * thanks to the 'styled' utility and engine provided by Mui.
 * Go to https://mui.com/system/styled/#main-content for more information about how this is working.
 * @param {Object} Component The component to stylize. The first argument of the 'styled' utility.
 * @param {Array<String>} extraPropsToNotSpread The list of the extra props that must not be passed to the DOM
 * (because not defined by the Component to stylize) but only used by the utility for customization.
 * @param {function} stylizationFunction The function that takes props and return the styles.
 * @return {Object} The styled Component to use in the DOM.
 */
const stylizeComponent = (Component, extraPropsToNotSpread = [], stylizationFunction) => (
  styled(
    Component,
    {
      shouldForwardProp: (propName) => !extraPropsToNotSpread.includes(propName),
    },
  )(stylizationFunction)
);

export default stylizeComponent;
