// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

export default function paymentReceipt(client) {
  const path = "/payment-receipt";
  return {
    list: (data) => client.request({
      endpoint: `${path}?payment_payload=${data}`,
      method: "GET",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
  };
}
