/* eslint import/prefer-default-export: 0 */

/**
 * This file provides all the constants and color related to the Weezevent corporate identity and style guide.
 */

export const COLOR_BLUE_DEEP = "#14142d";
export const COLOR_BLUE_WEEZTICKET = "#0032fa";
export const COLOR_BLUE_WEEZTICKET_ALT_1 = "#146def";
export const COLOR_BLUE_WEEZTICKET_ALT_2 = "#003cd3";
export const COLOR_BLUE_WEEZTICKET_ALT_3 = "#0033b2";

export const COLOR_GENERIC_BLACK = "#000000";
export const COLOR_GENERIC_BLACK_ANTHRACITE = "#0f0f14";
export const COLOR_GENERIC_BLACK_DARK = "#121212";

export const COLOR_GENERIC_GREY_A = "#1e2332";
export const COLOR_GENERIC_GREY_B = "#555a69";
export const COLOR_GENERIC_GREY_C = "#878c9b";
export const COLOR_GENERIC_GREY_D = "#a5aab9";
export const COLOR_GENERIC_GREY_E = "#c8cddc";
export const COLOR_GENERIC_GREY_F = "#dce1f0";

export const COLOR_GENERIC_WHITE = "#ffffff";
export const COLOR_GENERIC_WHITE_ICE = "#f0f5fa";

export const COLOR_YELLOW_WEEZPAY = "#ffcc33";
export const COLOR_YELLOW_WEEZPAY_ALT_1 = "#cc9e29";
export const COLOR_YELLOW_WEEZPAY_ALT_2 = "#e5b12e";
export const COLOR_YELLOW_WEEZPAY_ALT_3 = "#ffd366";
export const COLOR_YELLOW_WEEZPAY_ICE = "#f7f3eb";
