export default function timeslots(client) {
  const path = "/timeslots";

  function available(walletId, periodId, zoneId) {
    const params = `wallet_id=${walletId}&period_id=${periodId}&zone_id=${zoneId}`;
    return client.request({
      endpoint: `/widgets/${client.config.widgetUUID}${path}-available?${params}`,
      method: "GET",
    });
  }

  return {
    list: () => client.request({
      endpoint: `/widgets/${client.config.widgetUUID}${path}`,
      method: "GET",
    }),
    available: (walletId, periodId, zoneId) => available(walletId, periodId, zoneId),
    select: (walletId, periodId, zoneId, timeslotId) => client.request({
      endpoint: `/widgets/${client.config.widgetUUID}${path}-select`,
      method: "POST",
      body: {
        wallet_id: walletId,
        zone_id: zoneId,
        period_id: periodId,
        timeslot_id: timeslotId,
      },
    }),
  };
}
