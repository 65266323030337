import { queryParams, jsonRender } from "libs/api/interceptors";

export default function History(client, config) {
  return {
    list: (limit, offset, responseInterceptors) => client.request({ // params: limit, offset
      endpoint: `/widgets/${config.widgetUUID}/history`,
      method: "GET",
      interceptors: [queryParams({ limit, offset })],
      responseInterceptors: [...(responseInterceptors || [jsonRender()])],
    }),
  };
}
