// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";
/**
 * This API is to retrieve the scenarios related to the widget.
 * A scenario is a rule that describes incentives or free top-ups given to a user depending on an action he does.
 */
export default function scenarios(client, config) {
  const path = `/widgets/${config.widgetUUID}/scenarios`;
  return {
    list: () => client.request({
      endpoint: `${path}?type=R`,
      method: "GET",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
  };
}
