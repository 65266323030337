import { interceptors } from "libs/api";

export default function User(client, config) {
  const path = `/widgets/${config.widgetUUID}/users`;
  return {
    me: () => client.request({
      version: "v2",
      endpoint: `${path}/me`,
      method: "GET",
    }),
    update: (data) => client.request({
      version: "v2",
      endpoint: `${path}/me`,
      method: "PATCH",
      body: data,
    }),
    create: (data) => client.request({
      version: "v2",
      endpoint: path,
      method: "POST",
      body: data,
    }),
    createWithCas: (data, sessionId) => client.request({
      interceptors: [interceptors.headers({ Authorization: `Session ${sessionId}` })],
      endpoint: `${path}/complete_registration`,
      method: "POST",
      body: {
        provider: "cas",
        user: data,
      },
    }),
    resetPassword: (data) => client.request({
      endpoint: `${path}/reset_password`,
      method: "POST",
      body: { ...data, widget_uuid: config.widgetUUID },
    }),
    resetPasswordConfirm: (uid, data) => client.request({
      endpoint: `${path}/${uid}/reset_password_confirm`,
      method: "POST",
      body: data,
    }),
  };
}
