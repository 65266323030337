export default function topUps(client) {
  const path = "/top-ups";
  return {
    create: (data) => client.request({
      version: "v2",
      endpoint: `/widgets/${client.config.widgetUUID}${path}`,
      method: "POST",
      body: data,
    }),
    get: (id) => client.request({
      version: "v2",
      endpoint: `/widgets/${client.config.widgetUUID}${path}/${id}`,
      method: "GET",
    }),
  };
}
