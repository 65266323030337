export default function wallets(client, config) {
  const path = `/widgets/${config.widgetUUID}/wallets`;
  return {
    create: (data) => client.request({ endpoint: path, method: "POST", body: data }),
    get: (id) => client.request({ endpoint: `${path}/${id}`, method: "GET" }),
    list: () => client.request({ endpoint: `${path}?ordering=id`, method: "GET" }),
    partialUpdate: (id, data) => client.request({ endpoint: `${path}/${id}`, method: "PATCH", body: data }),
    mobilePayment: (id) => client.request({ endpoint: `${path}/${id}/mobile_payment`, method: "GET" }),
    getBalances: (id) => client.request({ endpoint: `${path}/${id}/balances`, method: "GET" }),
    getBarcodesInfo: (data) => client.request({
      version: "v2",
      endpoint: `${path}/actions`,
      method: "POST",
      body: {
        type: "CHECK_BARCODES",
        config: data,
      },
    }),
  };
}
