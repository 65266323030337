import React from "react";

const initialContext = () => {
  throw new Error("Trying to use AuthenticationContext with no provider.");
};

const AuthenticationContext = React.createContext(initialContext);

if (process.env.NODE_ENV !== "production") {
  AuthenticationContext.displayName = "AuthenticationContext";
}

export default AuthenticationContext;
