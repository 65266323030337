import React from "react";

import PropTypes from "prop-types";

import I18nLocaleSelector from "components/I18nLocaleSelector";

import Container from "./components/Container";
import Subtitle from "./components/Subtitle";
import Title from "./components/Title";

/**
 * A card layout component to group title, subtitle and content.
 * This card is the base card used in the whole cashless application.
 */
const Card = ({
  children,
  error,
  subtitle,
  title,
  padding,
  margin,
  color,
  backgroundColor,
  borderColor,
  displayBorder,
  isSelected,
  shouldDisplayLocaleSelector,
  I18nLocaleSelectorProps,
  sx,
}) => (
  <Container
    padding={padding}
    margin={margin}
    color={color}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    displayBorder={displayBorder}
    isSelected={isSelected}
    sx={sx}
  >
    {error}
    {title && (
      <Title
        shouldDisplayLocaleSelector={shouldDisplayLocaleSelector}
        I18nLocaleSelectorProps={I18nLocaleSelectorProps}
      >
        {title}
      </Title>
    )}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {children}
  </Container>
);

Card.propTypes = {
  /**
   * The error content of the component.
   */
  error: PropTypes.any,
  /**
   * The main content of the component.
   */
  children: PropTypes.any,
  /**
   * The main text/title of the component.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * The description/subtitle of the component.
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * If we want to override or not the default paddings of the container.
   */
  padding: PropTypes.string,
  /**
   * If we want to override the default margins of the container.
   */
  margin: PropTypes.string,
  /**
   * Color theme of the component.
   */
  color: PropTypes.oneOf(["default", "primary"]),
  /**
   * The background color to override (instead of the default one computed by 'color').
   */
  backgroundColor: PropTypes.string,
  /**
   * The border color to override (instead of the default one computed by 'color'.
   */
  borderColor: PropTypes.string,
  /**
   * Whether we don't want to remove the borders of the card.
   */
  displayBorder: PropTypes.bool,
  /**
   * Whether the component is selected in order to display the UI / color of a selected block.
   */
  isSelected: PropTypes.bool,
  /**
   * Whether we want to display the locale selector.
   */
  shouldDisplayLocaleSelector: PropTypes.bool,
  /**
   * The props to pass to the LocalSelector component.
   */
  I18nLocaleSelectorProps: PropTypes.shape(I18nLocaleSelector.propTypes),
  /**
   * The free style props (from 'mui').
   */
  sx: PropTypes.object,
};

Card.defaultProps = {
  error: null,
  children: null,
  title: null,
  subtitle: null,
  padding: null,
  margin: null,
  color: "default",
  backgroundColor: null,
  borderColor: null,
  displayBorder: true,
  isSelected: false,
  shouldDisplayLocaleSelector: false,
  I18nLocaleSelectorProps: {},
  sx: {},
};

export default Card;
