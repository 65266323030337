import React from "react";

import { FluentBundle, FluentResource } from "@fluent/bundle";
import { LocalizationProvider, ReactLocalization } from "@fluent/react";
import "intl-pluralrules";
import PropTypes from "prop-types";

import Loader from "components/Loader";

const FluentProvider = ({ files, children, locales, overwrittenLocales }) => {
  const [bundles, setBundles] = React.useState([]);

  React.useEffect(() => {
    if (locales?.length) {
      const bundle = new FluentBundle(locales[0]);
      locales.forEach((locale) => {
        if (overwrittenLocales && locale in overwrittenLocales) {
          bundle.addResource(new FluentResource(overwrittenLocales[locale]));
        }
        bundle.addResource(new FluentResource(files[locale]));
      });
      setBundles([bundle]);
    }
  }, [files, locales, overwrittenLocales]);

  if (bundles.length === 0) {
    return <Loader margin="15px 0px 0px 0px" />;
  }

  return (
    <LocalizationProvider l10n={new ReactLocalization(bundles)}>
      {children}
    </LocalizationProvider>
  );
};

FluentProvider.propTypes = {
  /**
   * The files from the .ftl format to import the translations.
   */
  files: PropTypes.object,
  /**
   * The list of all locales that must be supported by the application and for which we need translations.
   */
  locales: PropTypes.arrayOf(PropTypes.string),
  /**
   * The translations to use to overwrite those from the files.
   */
  overwrittenLocales: PropTypes.object,
  /**
   * The whole application to provide the translations.
   */
  children: PropTypes.node.isRequired,
};

FluentProvider.defaultProps = {
  files: null,
  locales: [],
  overwrittenLocales: null,
};

export default FluentProvider;
