import React from "react";

/**
 * This context has to store the ID of the organizer.
 */
const OrganizationIdContext = React.createContext(null);

if (process.env.NODE_ENV !== "production") {
  OrganizationIdContext.displayName = "OrganizationIdContext";
}

export default OrganizationIdContext;
