import { negotiateLanguages } from "@fluent/langneg";
import { useLocalization } from "@fluent/react";

export const negociateLanguages = (...args) => negotiateLanguages(...args);

export const getString = (id, ...args) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { l10n } = useLocalization();
  return l10n.getString(id, ...args);
};
