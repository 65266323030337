import React from "react";

import WidgetUUIDContext from "contexts/WidgetUUIDContext";

/**
 * This hook return the UUID of the widget stored in the WidgetUUIDContext context.
 */
const useWidgetUUID = () => React.useContext(WidgetUUIDContext);

export default useWidgetUUID;
