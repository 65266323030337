import React from "react";

import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import "core-js/stable";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";

import App from "routers/App";

if (window.weezConfig.sentry) {
  Sentry.init({
    dsn: window.weezConfig.sentry.dsn,
    environment: window.weezConfig.sentry.environment,
    release: window.weezConfig.version || "NO_RELEASE",
    beforeSend(event) {
      if (event.browser === "MiuiBrowser") {
        // We have a tons of stupid errors on MiuiBrowesr
        // like "Intl.DateTimeFormat is not a constructor"
        // We don't want to clutter Sentry with those
        return null;
      }
      return event;
    },
  });
}

if (window.weezConfig.datadog) {
  datadogRum.init({
    applicationId: window.weezConfig.datadog.applicationId,
    clientToken: window.weezConfig.datadog.clientToken,
    site: window.weezConfig.datadog.site,
    service: window.weezConfig.datadog.service,
    sampleRate: window.weezConfig.datadog.sampleRate,
    // replace to sessionReplaySampleRate when it becomes deprecated
    // https://docs.datadoghq.com/real_user_monitoring/guide/sampling-browser-plans/
    premiumSampleRate: window.weezConfig.datadog.premiumSampleRate,
    trackInteractions: window.weezConfig.datadog.trackInteractions,
    defaultPrivacyLevel: window.weezConfig.datadog.defaultPrivacyLevel,
    // version: '1.0.0',
  });
  datadogRum.startSessionReplayRecording();
}

function mount() {
  const ref = { current: null };
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App ref={ref} />
      </BrowserRouter>
    </React.StrictMode>,
  );

  return {
    setTitle: (title) => ref?.current?.setTitle(title),
  };
}

window.appIntegration = mount();

// We may want to enable Progressive Web Apps in the future, so we kept the src/serviceWorker.js file.
// To enable it we have to uncomment the import and move it in the import section,
// then uncomment serviceWorker.register();
// Learn more about service workers: https://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
// serviceWorker.register();
