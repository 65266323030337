import React from "react";

import PropTypes from "prop-types";

const DummyProvider = ({ children }) => <div>{children}</div>;

DummyProvider.propTypes = {
  /**
   * The whole application to wrap in order to provide the data.
   */
  children: PropTypes.string.isRequired,
};

export default DummyProvider;
