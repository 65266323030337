import React from "react";

import PropTypes from "prop-types";
import { useMutation } from "react-query";

import LocaleSelector from "components/LocaleSelector";
import { useAPI } from "libs/api";
import { useAuthentication } from "libs/authentication";
import { useI18n } from "libs/i18n";

/**
 * This component is a specialised component that use the generic LocaleSelector component in order
 * to display a dropdown selector to select the locale to use.
 * It's linked to the i18n lib.
 * It provided a way to override the props to pass to the LocalSelector component to not use the i18n global system.
 */
const I18nLocaleSelector = ({
  availableLocales,
  selectedLocale,
  onChange,
  displayFlag,
  displayNativeName,
  shouldSaveLocaleOnChange,
}) => {
  const api = useAPI("WeezPay");
  const { isAuthenticated } = useAuthentication();
  const {
    allowedLocales: i18nAllowedLocales,
    locale: i18nLocale,
    setLocale: i18nSetLocale,
  } = useI18n();

  const userUpdateMutation = useMutation(
    (data) => api.users.update(data),
  );

  const onChangeValue = React.useCallback(
    async (value) => {
      if (onChange) {
        onChange(value, i18nLocale !== value);
      }
      i18nSetLocale(value);
      try {
        if (shouldSaveLocaleOnChange && i18nLocale !== value && isAuthenticated) {
          await userUpdateMutation.mutateAsync({ language: value });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Save locale to the user preferences failed.");
      }
    },
    [onChange, i18nSetLocale, i18nLocale, shouldSaveLocaleOnChange, isAuthenticated, userUpdateMutation],
  );

  return (
    <LocaleSelector
      availableLocales={availableLocales !== undefined ? availableLocales : i18nAllowedLocales}
      selectedLocale={selectedLocale !== undefined ? selectedLocale : i18nLocale}
      onChange={onChangeValue}
      displayFlag={displayFlag}
      displayNativeName={displayNativeName}
    />
  );
};

I18nLocaleSelector.propTypes = {
  /**
   * The list of available locales that can be selected.
   */
  availableLocales: PropTypes.arrayOf(PropTypes.oneOf(["ca", "de", "en", "es", "fi", "fr", "hu", "nl", "pt"])),
  /**
   * The default selected locale.
   */
  selectedLocale: PropTypes.oneOf(["ca", "de", "en", "es", "fi", "fr", "hu", "nl", "pt"]),
  /**
   * The callback to trigger once we select a locale.
   */
  onChange: PropTypes.func,
  /**
   * Whether we want to display the associated flag to the locale.
   */
  displayFlag: PropTypes.bool,
  /**
   * Whether we want to display the language related to the locale in the native name (from the locale itself)
   * If false, the language name is displayed from the current selected locale.
   */
  displayNativeName: PropTypes.bool,
  /**
   * Whether we want to save the locale selection in the user by calling the API or not.
   */
  shouldSaveLocaleOnChange: PropTypes.bool,
};

I18nLocaleSelector.defaultProps = {
  availableLocales: undefined, // Use the i18n allowed locales by default
  selectedLocale: undefined, // Use the i18n allowed locales by default
  onChange: undefined, // Use the i18n behaviour (change the locale in the context) by default
  displayFlag: false,
  displayNativeName: true,
  shouldSaveLocaleOnChange: true,
};

export default I18nLocaleSelector;
