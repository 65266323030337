// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

export default function auth(client, config) {
  const path = `/widgets/${config.widgetUUID}/auth`;
  return {
    create: (data) => client.request({
      endpoint: path,
      method: "POST",
      body: data,
    }),
    list: () => client.request({
      endpoint: path,
      method: "GET",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
    destroy: () => client.request({
      endpoint: path,
      method: "DELETE",
    }),
  };
}
