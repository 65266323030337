import ca from "locales/ca.ftl";
import de from "locales/de.ftl";
import el from "locales/el.ftl";
import en from "locales/en.ftl";
import es from "locales/es.ftl";
import fi from "locales/fi.ftl";
import fr from "locales/fr.ftl";
import hu from "locales/hu.ftl";
import nl from "locales/nl.ftl";
import pt from "locales/pt.ftl";

export const SUPPORTED_LOCALES = {
  ca: "ca",
  de: "de",
  el: "el",
  en: "en",
  en_CA: "en-CA",
  en_GB: "en-GB",
  en_US: "en-US",
  es: "es",
  es_MX: "es-MX",
  fi: "fi",
  fr: "fr",
  hu: "hu",
  nl: "nl",
  pt: "pt",
};

export const LOCALES_NATIVE_NAMES = {
  [SUPPORTED_LOCALES.ca]: "Català",
  [SUPPORTED_LOCALES.de]: "Deutsch",
  [SUPPORTED_LOCALES.el]: "Greek",
  [SUPPORTED_LOCALES.en]: "English",
  [SUPPORTED_LOCALES.en_CA]: "English",
  [SUPPORTED_LOCALES.en_GB]: "English",
  [SUPPORTED_LOCALES.en_US]: "English",
  [SUPPORTED_LOCALES.es]: "Español",
  [SUPPORTED_LOCALES.es_MX]: "Español",
  [SUPPORTED_LOCALES.fi]: "Suomi",
  [SUPPORTED_LOCALES.fr]: "Français",
  [SUPPORTED_LOCALES.hu]: "Magyar",
  [SUPPORTED_LOCALES.nl]: "Nederlands",
  [SUPPORTED_LOCALES.pt]: "Português",
};

const FTL_FILES = {
  [SUPPORTED_LOCALES.ca]: ca,
  [SUPPORTED_LOCALES.de]: de,
  [SUPPORTED_LOCALES.el]: el,
  [SUPPORTED_LOCALES.en]: en,
  [SUPPORTED_LOCALES.en_CA]: en,
  [SUPPORTED_LOCALES.en_GB]: en,
  [SUPPORTED_LOCALES.en_US]: en,
  [SUPPORTED_LOCALES.es]: es,
  [SUPPORTED_LOCALES.es_MX]: es,
  [SUPPORTED_LOCALES.fi]: fi,
  [SUPPORTED_LOCALES.fr]: fr,
  [SUPPORTED_LOCALES.hu]: hu,
  [SUPPORTED_LOCALES.nl]: nl,
  [SUPPORTED_LOCALES.pt]: pt,
};

const fetchMessages = async (locale, ftl) => {
  const response = await fetch(ftl);
  const messages = await response.text();
  return [locale, messages];
};

export const getLocaleFiles = async () => {
  const localesEntries = await Promise.all(
    Object.entries(FTL_FILES).map(([locale, ftl]) => fetchMessages(locale, ftl)),
  );
  return Object.fromEntries(localesEntries);
};
