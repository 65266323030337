import React from "react";

import PropTypes from "prop-types";

const DummyTranslate = ({ id }) => <>{id}</>;

DummyTranslate.propTypes = {
  /**
   * The translation key / id to fetch in the translation file.
   */
  id: PropTypes.string.isRequired,
};

export default DummyTranslate;
