import React from "react";

import APIContext from "../contexts/APIContext";

const useAPI = (key) => {
  if (!key) {
    return undefined;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return React.useContext(APIContext)[key];
};

export default useAPI;
