import React from "react";

import {
  ArrowDropDown as ArrowDropDownIcon, ArrowDropUp,
  Language as LanguageIcon,
} from "@mui/icons-material";
import { Box, Grid, SvgIcon, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { LOCALES_NATIVE_NAMES } from "locales/config";
import stylizeComponent from "styles/stylizeComponent";

const StyledBoxForContainer = stylizeComponent(
  Box,
  [],
  ({ theme }) => ({
    "&.MuiBox-root": {
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
    },
    "&:hover .MuiSvgIcon-root, &:hover .selectedValueTypography": {
      color: theme.palette.primary.main,
    },
  }),
);

const StyledBoxForDropDown = stylizeComponent(
  Box,
  [],
  ({ theme }) => ({
    "&.MuiBox-root": {
      position: "absolute",
      backgroundColor: theme.palette.background.default,
      border: `solid 0.0625rem ${theme.palette.grey.grey2}`,
      borderRadius: "6px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
      marginTop: "5px",
      padding: "0.25rem 0rem",
      zIndex: 1000,
      right: 0,
    },
  }),
);

const StyledGridForDropDownItem = stylizeComponent(
  Grid,
  [],
  ({ theme }) => ({
    "&.MuiGrid-root": {
      width: "100%",
      padding: "0.5rem 0.75rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.primary.background,
      },
    },
  }),
);

const StyledTypographyForDropDownItemLabel = stylizeComponent(
  Typography,
  ["isSelected"],
  ({ theme, isSelected }) => ({
    "&.MuiTypography-root": {
      color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  }),
);

const StyledTypographyForSelectedItemLabel = stylizeComponent(
  Typography,
  ["isOpen"],
  ({ theme, isOpen }) => ({
    "&.MuiTypography-root": {
      color: isOpen ? theme.palette.primary.main : theme.palette.text.primary,
    },
  }),
);

const StyledSvgIcon = stylizeComponent(
  SvgIcon,
  ["isSelected"],
  ({ theme, isSelected }) => ({
    "&.MuiSvgIcon-root": {
      fontSize: "1rem",
      display: "block",
      color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
    },
  }),
);

/**
 * This component is used to display a dropdown selector to select the locale to use.
 */
const LocaleSelector = ({
  availableLocales,
  selectedLocale,
  onChange,
  displayFlag,
  /* eslint-disable-next-line no-unused-vars */
  displayNativeName,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedLocaleValue, setSelectedLocaleValue] = React.useState(selectedLocale);

  const onSelect = React.useCallback(
    (value) => () => {
      setSelectedLocaleValue(value);
      setOpen(false);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <StyledBoxForContainer>
      <Grid container alignItems="center" onClick={() => setOpen(((prevState) => !prevState))} wrap="nowrap">
        <Grid item marginRight="0.25rem">
          <StyledSvgIcon component={LanguageIcon} isSelected={open} />
        </Grid>
        <Grid item>
          <StyledTypographyForSelectedItemLabel isOpen={open} className="selectedValueTypography">
            {selectedLocaleValue.toUpperCase()}
          </StyledTypographyForSelectedItemLabel>
        </Grid>
        <Grid item>
          {open
            ? <StyledSvgIcon component={ArrowDropUp} isSelected={open} />
            : <StyledSvgIcon component={ArrowDropDownIcon} isSelected={open} />}
        </Grid>
      </Grid>
      {open && (
        <StyledBoxForDropDown>
          <Grid container alignItems="flex-start" flexDirection="column" wrap="nowrap">
            {availableLocales.map((availableLocale) => (
              <StyledGridForDropDownItem
                key={availableLocale}
                onClick={onSelect(availableLocale)}
                item
                xs
              >
                <Grid container alignItems="center">
                  {displayFlag && (
                    <Grid item>
                      <LanguageIcon className="wz-g-mui-icon" />
                    </Grid>
                  )}
                  <Grid item>
                    <StyledTypographyForDropDownItemLabel isSelected={availableLocale === selectedLocale}>
                      {LOCALES_NATIVE_NAMES[availableLocale]}
                      &nbsp;({availableLocale.toUpperCase()})
                    </StyledTypographyForDropDownItemLabel>
                  </Grid>
                </Grid>
              </StyledGridForDropDownItem>
            ))}
          </Grid>
        </StyledBoxForDropDown>
      )}
    </StyledBoxForContainer>
  );
};

LocaleSelector.propTypes = {
  /**
   * The list of available locales that can be selected.
   */
  availableLocales: PropTypes.arrayOf(PropTypes.oneOf(["ca", "de", "en", "es", "fi", "fr", "hu", "nl", "pt"])),
  /**
   * The default selected locale.
   */
  selectedLocale: PropTypes.oneOf(["ca", "de", "en", "es", "fi", "fr", "hu", "nl", "pt"]),
  /**
   * The callback to trigger once we select a locale.
   */
  onChange: PropTypes.func,
  /**
   * Whether we want to display the associated flag to the locale.
   */
  displayFlag: PropTypes.bool,
  /**
   * Whether we want to display the language related to the locale in the native name (from the locale itself)
   * If false, the language name is displayed from the current selected locale. TODO.
   */
  displayNativeName: PropTypes.bool,
};

LocaleSelector.defaultProps = {
  availableLocales: ["en"],
  selectedLocale: "en",
  onChange: null,
  displayFlag: false,
  displayNativeName: true,
};

export default LocaleSelector;
