// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

// This is to call the API without any invalid credentials. Since this endpoint is open.
const UNAUTHORIZED_INTERCEPTOR = removeHeader("Authorization");

export default function rawReceipts(client) {
  const path = "/receipts";
  return {
    getTransactionData: (transactionRawData) => client.request({
      endpoint: `${path}/actions`,
      version: "v2",
      body: {
        type: "GET_TRANSACTION_DATA",
        config: {
          transaction_raw_data: transactionRawData,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
    sendByEmail: (transactionRawData, email, formData) => client.request({
      endpoint: `${path}/actions`,
      version: "v2",
      body: {
        type: "SEND_TRANSACTION_RECEIPT_EMAIL",
        config: {
          email_address: email,
          form_data: formData,
          transaction_raw_data: transactionRawData,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
    getPdf: (transactionRawData, formData) => client.request({
      endpoint: `${path}/actions`,
      version: "v2",
      body: {
        type: "GET_TRANSACTION_RECEIPT_PDF_DATA",
        config: {
          form_data: formData,
          transaction_raw_data: transactionRawData,
        },
      },
      method: "POST",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
  };
}
