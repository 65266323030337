/* eslint-disable import/prefer-default-export */

/**
 * Return the object without the entries for which the value is included in the forbidden values.
 * @param {Object} obj The object to filter / clean.
 * @param {Array.<*|null|undefined>} valuesToClean The forbidden values.
 * @return {Object} The filtered / clean object.
 */
// eslint-disable-next-line arrow-body-style
export const cleanObjectOnValues = (obj, valuesToClean) => {
  if (typeof obj !== "object" || !valuesToClean) {
    return obj;
  }
  return Object.fromEntries(Object.entries(obj).filter((entry) => !valuesToClean.includes(entry[1])));
};

/**
 * Compare shallow equality between two objects: same keys and same values for same keys with no consideration
 * for references (deep equality)
 * @param {Object} object1 first object
 * @param {Object} object2 second object
 * @return {boolean} true if objects are equal
 */
export const compareObjects = (object1, object2) => {
  if (!!object1 !== !!object2) {
    return false;
  }
  if (!object1 && !object2) {
    return true;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // use length instead of array iteration because it uses "regenerator-runtime" and it is "too heavyweight"
  return keys1.every((key) => object1[key] === object2[key]);
};

/**
 * Retrieve the appropriate translation for an object field
 * (by taking the explicit translated value returned when available or the fallback).
 * The object comes from the backend and has a well-known structure.
 * @param {Object} obj The object that comes from the backend.
 * @param {string} originalValue The key / the field name for which we want the translated value.
 * @param {string} locale The locale code for which we want the translation.
 * @return {string} The translated value according to the field name and the locale.
 */
export const retrieveFromTranslation = (obj, originalValue, locale, useFallback = true) => {
  // eslint-disable-next-line no-prototype-builtins
  if (obj.translations && obj.translations.hasOwnProperty(originalValue) && obj.translations[originalValue]) {
    return obj.translations[originalValue][locale] || obj[originalValue];
  }
  if (useFallback) {
    return obj[originalValue];
  }
  return null;
};

/**
 * Encode a list of query params (into an object) to be able to pass it in the url.
 * @param {Object} queryParams The object with the query parameters to encode (key, value).
 * @return {string|null} The encoded query params string.
 */
export const encodeQueryParams = (queryParams) => {
  if (!queryParams) {
    return null;
  }
  const urlSearchParams = new URLSearchParams();
  Object.entries(queryParams).forEach(([key, value]) => {
    urlSearchParams.append(key, value);
  });
  return urlSearchParams.toString();
};

/**
 * Return the nested value of an object from the path describes in a string.
 * @param obj The nested object to investigate and to extract the value.
 * @param stringPath The path of the key related to the value to extract.
 * @param separator The separator used in the string path to split the levels.
 * @return {null|*} The value if the path was good or null if the nested path failed.
 */
export const getValueInNestedObject = (obj, stringPath, separator = ".") => {
  if (!obj || !stringPath) {
    return null;
  }
  let result = { ...obj };
  let error = false;
  const nestedPath = stringPath.split(separator);
  nestedPath.forEach((path) => {
    // eslint-disable-next-line no-prototype-builtins
    if (result && result.hasOwnProperty(path)) {
      result = result[path];
    } else {
      error = true;
    }
  });
  return error ? null : result;
};

export const getWithoutNullsAndUndefined = (obj) => Object.fromEntries(
  Object.entries(obj)
    .filter(([, v]) => v !== undefined && v !== null),
);
