import {
  DummyTranslate,
  DummyProvider,
  negociateLanguages as dummyNegociateLanguages,
  getString as dummyGetString,
} from "./dummy";
import {
  FluentTranslate,
  FluentProvider,
  negociateLanguages as fluentNegociateLanguage,
  getString as fluentGetString,
} from "./fluent";

function getBackend(backend) {
  let Translate = null;
  let Provider = null;
  let negotiateLanguages = null;
  let getString = null;
  if (backend === "dummy") {
    Translate = DummyTranslate;
    Provider = DummyProvider;
    negotiateLanguages = dummyNegociateLanguages;
    getString = dummyGetString;
  } else if (backend === "fluent") {
    Translate = FluentTranslate;
    Provider = FluentProvider;
    negotiateLanguages = fluentNegociateLanguage;
    getString = fluentGetString;
  } else {
    throw new Error(`Backend '${backend}' not found.`);
  }
  return { Translate, Provider, negotiateLanguages, getString };
}

export default { getBackend };
