import React from "react";

import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  subtitle: {
    fontSize: "0.875rem",
    lineHeight: "normal",
  },
};

/**
 * A component wrapper to display the embedded text with the subtitle style.
 */
const Subtitle = ({ children }) => (
  <Box marginBottom="15px">
    <Typography style={styles.subtitle}>{children}</Typography>
  </Box>
);

Subtitle.propTypes = {
  /**
   * The text/element to embed with the subtitle style.
   */
  children: PropTypes.any.isRequired,
};

export default Subtitle;
