import { headers, queryParams, saveAsFile } from "libs/api/interceptors";

export default function Orders(client, config) {
  const path = `/widgets/${config.widgetUUID}/orders`;
  return {
    get: (id) => client.request({
      version: "v2",
      endpoint: `${path}/${id}`,
      method: "GET",
    }),
    list: (queryParamsInput) => client.request({
      version: "v2",
      endpoint: `${path}?ordering=-id`,
      method: "GET",
      interceptors: Object.values(queryParamsInput)?.length ? [queryParams(queryParamsInput)] : [],
    }),
    createInvoice: (orderId, data) => client.request({
      version: "v2",
      endpoint: `${path}/${orderId}/invoices`,
      method: "POST",
      body: data,
    }),
    getInvoicePDF: (orderId, invoiceId, filename) => client.request({
      endpoint: `${path}/${orderId}/invoices/${invoiceId}`,
      method: "GET",
      responseInterceptors: [saveAsFile(filename)],
      interceptors: [headers({ accept: "application/pdf" })],
    }),
  };
}
