import React from "react";

import PropTypes from "prop-types";

import APIContext from "./APIContext";

/**
 * The context provider of APIContext.
 */
// eslint-disable-next-line no-unused-vars
const APIContextProvider = ({ value, children }) => (
  <APIContext.Provider value={value}>
    {children}
  </APIContext.Provider>
);

APIContextProvider.propTypes = {
  /**
   * The value of the context. Should be an object with a key to identify the API and an API instance as a value.
   */
  value: PropTypes.object.isRequired,
  /**
   * The wrapped components that will have access to the context.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
};

export default APIContextProvider;
