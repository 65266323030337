// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

export default function fastTopUps(client) {
  const path = "/fast-top-ups";
  return {
    create: (data) => client.request({
      version: "v2",
      endpoint: `/widgets/${client.config.widgetUUID}${path}`,
      method: "POST",
      body: data,
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
    get: (id) => client.request({
      version: "v2",
      endpoint: `/widgets/${client.config.widgetUUID}${path}/${id}`,
      method: "GET",
      interceptors: [
        // This is to call the API without any invalid credentials. Since this endpoint is open.
        removeHeader("Authorization"),
      ],
    }),
  };
}
