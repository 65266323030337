import WebFont from "webfontloader";
import "./fonts.css";

/**
 * Here is the script to load the default "hard-coded" fonts in the application from providers like Google, Typekit...
 * The custom "local" fonts are declared in ./fonts.css and loaded if used by at least one component (css classes)
 * The custom "cloud" fonts could be loaded thanks to another Webfont.load deeper in the code once we receive the list
 * of fonts to load from the API (but we need to know from which provider we must fetch it).
 * The other way to load custom "cloud" fonts is to provide the list of css "font-face" object that will be included
 * directly including the src (url and type), the font-weight, the font-family and the font-display properties.
 */
WebFont.load({
  google: {
    families: ["Roboto:400,500,700"],
  },
});
