export default function receipts(client, config) {
  const path = `/widgets/${config.widgetUUID}/donations`;
  return {
    create: (data) => client.request({
      endpoint: path,
      method: "POST",
      body: data,
    }),
  };
}
