import React from "react";

import OrganizationIdContext from "contexts/OrganizationIdContext";

/**
 * This hook return the ID of the organizer stored in the WidgetUUIDContext context.
 */
const useOrganizationId = () => React.useContext(OrganizationIdContext);

export default useOrganizationId;
