/* eslint-disable import/prefer-default-export */
import { WeezSocket } from "@weezevent/piglet";

/**
 * Check whether the application is running in an iframe or not.
 * @return {boolean} Whether the application is currently running in an iframe or not.
 */
export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Check whether the application is running on iOS.
 * @return {boolean} Whether the application is currently running on iOS.
 */
export const isOnIOS = () => /iPhone|iPod|iPad/.test(window.navigator.userAgent);

/**
 * Check whether the application is running on Safari browser.
 * @return {boolean} Whether the application is currently running on Safari browser.
 */
export const isOnSafari = () => /Safari/.test(window.navigator.userAgent) && !/Chrom/.test(window.navigator.userAgent);

export const isTouchDevice = () => (
  ("ontouchstart" in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0)
);

/**
 * This function takes an url and add or override the query params of it.
 * @param {string|URL} url The base url to enhance.
 * @param {Object} queryParams The object that holds the query params to append to the url.
 * @return {string|null} The enhanced url with the query params or null if the provided url is not valid.
 */
export const addQueryParamsToUrl = (url, queryParams) => {
  if (!queryParams) {
    return url.toString();
  }

  // Prepare url and query params by default.
  let newUrl;
  let newParams;
  try {
    newUrl = new URL(url);
    newParams = new URLSearchParams(newUrl.search);
  } catch (e) {
    newParams = new URLSearchParams();
  }

  // Check the url is valid.
  if (!newUrl?.href?.startsWith("http")) {
    // TODO: Probably in a webview, find a way to take the url to be able to redirect.
    // eslint-disable-next-line no-console
    console.error("The URL of the page is not valid:", newUrl);
    return null;
  }

  // Set the url with the query params provided
  Object
    .entries(queryParams)
    .forEach(([k, v]) => {
      newParams.set(k, v);
    });

  return `${newUrl.origin}${newUrl.pathname}${newUrl.hash}?${newParams.toString()}`;
};

/**
 * An asynchronous function to get the URL of the current parent window including query params.
 * It's especially used when the app is integrated in an iframe.
 * @param {string} defaultParentUrl The default url if no parent url found (not in an iframe) or if invalid url found.
 * @param {Object|null} extraQueryParams Other query params to add in the returned url.
 * @return {Promise<string|null>} The parent url to redirect to be back in the current window.
 */
export const getWindowParentUrl = async (defaultParentUrl = window.location.href, extraQueryParams = null) => {
  let urlWidget;
  let url;
  let params;

  // Compute url and query params by default.
  try {
    url = new URL(defaultParentUrl);
    urlWidget = url;
    params = new URLSearchParams(url.search);
  } catch (e) {
    params = new URLSearchParams();
  }

  // When in an iframe, we have to get the parent url thanks to piglet (the script embedded at the top level).
  if (isInIframe()) {
    try {
      const pigletResponse = await WeezSocket.send(
        {
          type: "parent-url",
          origin: "gadget",
        },
        { needs_answer: true },
      );

      url = new URL(pigletResponse.payload.parentUrl);
      params = new URLSearchParams(url.search);
      params.set("scrollToWeezWidget", 1);
      params.set("redirectToWidgetPage", urlWidget);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Cannot get the parent URL, check the iframe is properly integrated.");
    }
  }

  return addQueryParamsToUrl(
    `${url.origin}${url.pathname}${url.hash}${params?.size ? `?${params.toString()}` : ""}`,
    extraQueryParams,
  );
};

/**
 * A function to clean the query params from the parent url.
 * @param {Array<string>} queryParamKeys The list of queryParams (keys) to remove from the parent url.
 */
export const cleanWindowParentUrl = (queryParamKeys = []) => {
  if (isInIframe()) {
    try {
      WeezSocket.send(
        {
          type: "clean-url-params",
          origin: "gadget",
          payload: {
            paramsKeys: queryParamKeys,
          },
        },
        { needs_answer: false },
      );
    } catch {
      // eslint-disable-next-line no-console
      console.error("Cannot clean the parent URL, check the iframe is properly integrated.");
    }
  } else {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    queryParamKeys.forEach((key) => {
      params.delete(key);
    });
    const newUrl = `${url.origin}${url.pathname}${url.hash}${params?.size ? `?${params.toString()}` : ""}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }
};
